<template>
	<div class="ArticleDetail">
		<h2>发布新闻</h2>
		<el-input placeholder="请输入标题" v-model="title" clearable />
		<p />
		<div ref="editor" style="text-align: left;z-index:-1;" ></div>
		<p />
		<el-select
			v-model="value"
			filterable
			clearable
			placeholder="请选择新闻类别(可留空)"
            style="width:400px;"
		>
			<el-option
				v-for="item in options"
				:key="item.value"
				:label="item.label"
				:value="item.value"
			>
			</el-option>
		</el-select>
		<p />
		<el-button type="primary" @click="submit()" style="width:400px;">发布</el-button>
	</div>
</template>
<script>
import E from "wangeditor";
import axios from "axios";
import qs from "qs";
var editor;

export default {
	name: "WriteArticle",
	data: function () {
		return {
			title: "",
			options: [
				{
					value: "athletics",
					label: "田径",
				},
				{
					value: "football",
					label: "足球",
				},
				{
					value: "basketball",
					label: "篮球",
				},
				{
					value: "contest",
					label: "首高",
				},
				{
					value: "swimming",
					label: "游泳",
				},
				{
					value: "volleyball",
					label: "排球",
				},
				{
					value: "badminton",
					label: "羽毛球",
				},
			],
			value: "",
		};
	},
	mounted: function () {
		editor = new E(this.$refs.editor);
		editor.config.menus = [
			"head",
			"bold",
			"fontSize",
			"fontName",
			"italic",
			"underline",
			"strikeThrough",
			"indent",
			"lineHeight",
			"foreColor",
			"backColor",
			"list",
			"justify",
			"quote",
			"table",
			"code",
			"splitLine",
			"undo",
			"redo",
		];
		editor.create();
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
        },
        submitOn(){
            this.$confirm("您确认要提交吗", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.submit();
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消操作",
						});
					});

        },
		submit() {
			if (!this.title) {
				this.messageBox("请输入新闻标题", "error");
				return;
			}
			if (!editor.txt.html()) {
				this.messageBox("请输入新闻内容", "error");
				return;
			}
			axios({
				url: "/api/news/add",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
				data: qs.stringify({
					title: this.title,
					content: editor.txt.html(),
					group: this.value,
				}),
			})
				.then((res) => {
					console.log(res);
					this.messageBox("发布新闻成功", "success");
					this.title = "";
					editor.txt.clear();
				})
				.catch((err) => {
					this.messageBox("发布新闻失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误", "error");
					console.log(err.response);
				});
		},
	},
};
</script>